import React from "react";
import UpdateRelaseItem from "./UpdateRelaseItem";
import { updateReleases } from "../../services/UpdatesReleasesServices";

const UpdateReleaseList = ({ rows = 0 }) => {
    return (
        <div className="flex flex-col space-y-10 w-full">
            {updateReleases.map((value, index) =>
                rows === 0 || index < rows ? <UpdateRelaseItem {...value} /> : ""
            )}
        </div>
    );
};

export default UpdateReleaseList;
