import React, { useEffect } from "react";

import "./admin.css";

import HeaderSection from "../components/ui/HeaderSection";

import Container from "../components/ui/Container";
import { Outlet, useLocation } from "react-router-dom";
import SideNav from "../sections/admin/SideNav";
import { Disclosure } from "@headlessui/react";
import { adminPanelColor } from "../services/defaultSettings";
import { page } from "../services/pageServices";
import { adminPages } from "../services/adminServices";

const AdminPanel = () => {
    const screenWidth = window.innerWidth;

    const location = useLocation();

    useEffect(() => {
        const locationSegments = location.pathname.split("/");
        const isLastSegment = adminPages.filter(
            (element) => element.to === locationSegments[locationSegments.length - 1]
        );

        const isBeforeLastSegment = adminPages.filter(
            (element) => element.to === locationSegments[locationSegments.length - 2]
        );

        let pageTitle = "";
        if (isLastSegment.length > 0 && isBeforeLastSegment.length < 1) {
            pageTitle = isLastSegment[0]["text"];
        } else if (isBeforeLastSegment.length > 0) {
            pageTitle = isBeforeLastSegment[0]["text"];
        }

        const currentTitle = page.getCurrentTitle();
        let title = "لوحة التحكم - " + pageTitle;
        page.setTitle(title);
        return () => {
            page.setTitle(currentTitle);
        };
    }, [location]);
    return (
        <>
            <Disclosure defaultOpen={screenWidth > 768}>
                {({ open }) => (
                    <div className="w-screen flex">
                        <div className="relative shrink-0">
                            <SideNav open={open} color={adminPanelColor} isFixed={true} />
                            {screenWidth > 768 ? (
                                <SideNav open={open} color={adminPanelColor} isFixed={false} />
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className={`bg-outer-container min-h-screen smooth clr-text-primary w-full overflow-auto ${
                                screenWidth < 768 ? "positive-side-nav-margin" : ""
                            }`}
                        >
                            <Container>
                                <HeaderSection />
                                <Container>
                                    <div className="-mt-40 z-10 relative">
                                        <Outlet />
                                    </div>
                                </Container>
                            </Container>
                        </div>
                    </div>
                )}
            </Disclosure>
        </>
    );
};

export default AdminPanel;
