import React, { useContext, useState } from "react";
import Electric from "../../components/svgs/Electric";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import MainBg from "../../components/svgs/MainBg";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/profile.png";
import bg from "../../assets/bg-star.svg";
import bgBottomLeft from "../../assets/bg-bttom-left.svg";
import bgTopRight from "../../assets/bg-top-right.svg";
const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin bg-yellow-400 relative overflow-hidden">
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-40"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div>
                <img
                    className="bottom-0 left-0 max-w-3xl w-full z-0 absolute opacity-75"
                    src={bgBottomLeft}
                    alt="bg-bottom-left"
                />
                <img
                    className="top-0 right-0 max-w-xs w-full z-0 absolute opacity-75"
                    src={bgTopRight}
                    alt="bg-bottom-left"
                />
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-20 md:space-y-0">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className=" flex-center-both flex-col clr-white smooth space-y-6 md:space-y-0">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-bigmax font-w-bold before-box relative">
                                    <span className="relative z-10">
                                        أ / محمد <span className="">أنــــور</span>
                                    </span>
                                </h1>
                                <div className=" flex-center-both flex-col">
                                    <h2 className="font-h2 text-slate-600">
                                        مــنــصــــــــــــــــــــــــــــــــة
                                        مــتــكـــــــــــــــــــــــامــلــة
                                    </h2>
                                    <div className="font-h2 relative text-slate-600">
                                        لشرح و امتحانــــات و تــــدريــــبــــات الأحياء
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
