import { createContext, useEffect, useState } from "react";
import auth from "../services/authServices";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState("");
    const [user, setUser] = useState({});

    const [isFreeTrial, setIsFreeTrial] = useState(false);
    const [isFreeTrialDone, setIsFreeTrialDone] = useState(false);
    const [freeTrialTimeLeft, setFreeTrialTimeLeft] = useState(0);
    const [isFreeTrialMenuClosed, setIsFreeTrialMenuClosed] = useState(false);

    const [isCheckedInvalidName, setIsCheckedInvalidName] = useState(false);
    const [isValidName, setIsValidName] = useState(false);

    const [isPrepaidChecked, setIsPrepaidChecked] = useState(false);

    const [admin, setAdmin] = useState({});

    const [prepaidCourses, setPrepaidCourses] = useState(0);

    const [adminToken, setAdminToken] = useState("");

    useEffect(() => {
        let authUser = auth.getUser();
        setUser(authUser ? authUser : {});
        setAdmin(auth.getAdmin());
    }, []);

    useEffect(() => {
        setIsPrepaidChecked(false);
        setToken(auth.getToken());
        if (user.full_name) {
            if (user.full_name.includes("random")) {
                setIsCheckedInvalidName(true);
                setIsValidName(false);
            } else {
                setIsCheckedInvalidName(false);
            }
        } else {
            setIsValidName(false);
            setIsCheckedInvalidName(false);
        }
        if (!auth.getToken()) {
            setIsFreeTrial(false);
            setIsFreeTrialDone(false);
            setFreeTrialTimeLeft(0);
        }
    }, [user, user.full_name]);

    useEffect(() => {
        setAdminToken(auth.getAdminToken());
    }, [admin]);

    return (
        <AuthContext.Provider
            value={{
                token,
                user,
                setUser,
                admin,
                adminToken,
                setAdmin,
                isFreeTrial,
                setIsFreeTrial,
                freeTrialTimeLeft,
                setFreeTrialTimeLeft,
                isFreeTrialDone,
                setIsFreeTrialDone,
                isFreeTrialMenuClosed,
                setIsFreeTrialMenuClosed,
                prepaidCourses,
                setPrepaidCourses,
                isPrepaidChecked,
                setIsPrepaidChecked,
                isCheckedInvalidName,
                setIsCheckedInvalidName,
                isValidName,
                setIsValidName,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
