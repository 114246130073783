import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "anwar-ma-admin";
export const domainName = "mohammed-anwar.com";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = false;
export const isCodes = false;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "yellow";
export const headerSectionColor = "yellow";
export const navbarIconColor = "text-yellow-400";
export const navbarDisclosureColor = "yellow";

export const progressBarColor = "yellow";
export const loadingBarColor = "yellow";

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "yellow";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "blue";
export const registerPageColor = "yellow";
