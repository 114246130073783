import React from "react";

import { ScrollingProvider } from "../../../context/ScrollingContext";

import SectionHead from "../../../components/ui/SectionHead";

// import year1 from "../../../../assets/year-2.jpeg";
// import Waves from "../../../components/ui/Waves";
import Categorie from "./Categorie";

const CoursesSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);
    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <ScrollingProvider>
                <SectionHead isWave={false} className="" title={title ? title : "الكورسات"} />
            </ScrollingProvider>

            <Categorie titleFirst="أحدث" titleLast="الكورسات" api={`/api/sellables`} />

            {/* <Waves className="fill-rose-200" /> */}
            {/* <div className="py-16 bg-rose-200"></div> */}
        </div>
    );
};

export default CoursesSection;
