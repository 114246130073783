import React from "react";
import { Navigate } from "react-router-dom";
import auth from "../services/authServices";

const ProtectedRoute = ({ children, replace = true }) => {
    const authToken = auth.getToken() ? true : false;

    return authToken ? (
        <>{children}</>
    ) : (
        <>
            <Navigate to="/login" replace={replace} />
        </>
    );
};

export default ProtectedRoute;
