import {
    isCodes,
    isCouponable,
    isLoginTokens,
    isManualPayment,
    isPrepaidSystem,
} from "./defaultSettings";

let pages = [
    {
        type: "breakline",
    },
    {
        to: "home",
        icon: "ant-design:home-twotone",
        text: "الصفحة الرئيسية",
        type: "page",
    },
    {
        to: "updates",
        icon: "icon-park-twotone:update-rotation",
        text: "آخر تحديثات المنصة",
        type: "page",
    },
    // {
    //     to: "stats",
    //     icon: "icomoon-free:stats-bars",
    //     text: "الاحصائيات",
    //     type: "page",
    // },
];
if (isManualPayment) {
    pages = [
        ...pages,
        {
            type: "breakline",
        },
        {
            to: "manual_payment_info",
            icon: "ant-design:info-circle-twotone",
            text: "معلومات الدفع",
            type: "page",
        },
    ];
}
if (isCodes) {
    pages = [
        ...pages,
        {
            type: "breakline",
        },
        {
            to: "insert_auto",
            icon: "mdi:qrcode-plus",
            text: "انشاء اكواد",
            type: "page",
        },
        {
            to: "insert_autos",
            // icon: "icon-park-twotone:pay-code-one",
            icon: "mdi:barcode-scan",
            text: "جدول الأكواد",
            type: "page",
        },
    ];
    if (isPrepaidSystem) {
        pages = [
            ...pages,
            {
                to: "user_prepaid_courses",
                icon: "simple-icons:contactlesspayment",
                text: "تعديل المحاضرات مسبقة الدفع",
                type: "page",
            },
        ];
    }
}
pages = [
    ...pages,
    {
        to: "",
        icon: "ant-design:home-twotone",
        text: "الصفحة الرئيسية",
        type: "hidden",
    },
    {
        type: "breakline",
    },
];
if (isLoginTokens) {
    pages = [
        ...pages,
        {
            to: "login_tokens",
            icon: "icomoon-free:enter",
            text: "مراجعة تسجيلات الدخول",
            type: "page",
        },
        {
            to: "logout_tokens",
            icon: "mdi:exit-run",
            text: "مراجعة تسجيلات الخروج",
            icon_properites: { flip: "horizontal" },
            type: "page",
        },
        {
            type: "breakline",
        },
    ];
}
pages = [
    ...pages,
    {
        to: "division",
        icon: "teenyicons:section-add-outline",
        text: "اضافة وتعديل الأقسام",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "course",
        icon: "ant-design:appstore-add-outlined",
        text: "اضافة وتعديل الكورسات",
        type: "page",
    },
    // {
    //     type: "breakline",
    // },
];
if (isCouponable) {
    pages = [
        ...pages,
        {
            to: "coupon",
            icon: "nimbus:discount-circle",
            text: "اضافة و تعديل الكوبونات",
            type: "page",
        },
    ];
}
pages = [
    ...pages,
    {
        type: "breakline",
    },
    {
        to: "section",
        icon: "clarity:blocks-group-solid-badged",
        text: "اضافة و تعديل المجموعات",
        type: "page",
    },
    {
        to: "course_sections_editing_page",
        icon: "fluent:group-return-24-regular",
        text: "تعديل مجموعات الكورس",
        type: "page",
    },
    {
        to: "course_sections_duplicating",
        icon: "pepicons:duplicate-print",
        text: "نقل مجموعات الكورس",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "video",
        icon: "ic:twotone-ondemand-video",
        text: "اضافة وتعديل الفيديوهات",
        type: "page",
    },
    {
        to: "book",
        icon: "ant-design:file-add-twotone",
        text: "اضافة وتعديل المذكرات",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "exam",
        icon: "ph:exam-duotone",
        text: "اضافة وتعديل الامتحانات",
        type: "page",
    },
    {
        to: "partition",
        icon: "uil:folder-question",
        text: "اضافة وتعديل مجاميع الأسئلة",
        type: "page",
    },
    {
        to: "question",
        icon: "ph:circle-wavy-question-duotone",
        text: "اضافة وتعديل الأسئلة",
        type: "page",
    },
    {
        to: "question_insert_auto",
        icon: "fluent:chat-bubbles-question-16-filled",
        text: "اضافة اكثر من سؤال",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "manual_subscription",
        icon: "ic:twotone-payment",
        text: "الدفع اليدوي",
        type: "page",
    },
    {
        to: "unsubscribe",
        icon: "ic:twotone-disabled-by-default",
        text: "الغاء الإشتراك",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "user_profile",
        icon: "carbon:user-profile",
        text: "ملف المستخدم",
        type: "page",
    },
    {
        to: "add_user",
        icon: "akar-icons:person-add",
        text: "اضافة طالب",
        type: "page",
    },
    {
        type: "breakline",
    },
    {
        to: "users",
        icon: "ph:users-duotone",
        text: "جدول المستخدمين",
        type: "page",
    },
    {
        to: "divisions",
        icon: "icon-park-twotone:intersection",
        text: "جدول الاقسام",
        type: "page",
    },
];
if (isCouponable) {
    pages = [
        ...pages,
        {
            to: "coupons",
            icon: "ic:twotone-discount",
            text: "جدول الكوبونات",
            type: "page",
        },
    ];
}
pages = [
    ...pages,
    {
        to: "courses",
        icon: "fa-solid:layer-group",
        text: "جدول الكورسات",
        type: "page",
    },
    {
        to: "videos",
        icon: "clarity:video-gallery-solid",
        text: "جداول الفيديوهات",
        type: "page",
    },
    {
        to: "exams",
        icon: "ph:exam-duotone",
        text: "جداول الامتحانات",
        type: "page",
    },
    {
        to: "partitions",
        icon: "ph:folders-duotone",
        text: "جداول مجموعات الاسئلة",
        type: "page",
    },
    {
        to: "questions",
        icon: "fluent:book-question-mark-rtl-20-filled",
        text: "جداول الاسئلة",
        type: "page",
    },
    {
        to: "exam_results",
        icon: "charm:graduate-cap",
        text: "جداول نتائج الامتحانات",
        type: "page",
    },
    {
        to: "hm_results",
        icon: "healthicons:i-exam-multiple-choice",
        text: "جداول نتائج الواجبات",
        type: "page",
    },
    {
        to: "subscriptions",
        icon: "eos-icons:activate-subscriptions",
        text: "جداول الإشتراكات",
        type: "page",
    },
    {
        to: "invoices",
        icon: "fa6-solid:file-invoice-dollar",
        text: "جداول الفواتير",
        type: "page",
    },
];

export const adminPages = pages;

export const submitTypes = [
    { value: "store", text: "اضافة" },
    { value: "update", text: "تعديل" },
    { value: "delete", text: "حذف" },
];
