import {
    isBtns,
    isCodes,
    isCouponable,
    isLoginTokens,
    isPlatformSubscription,
    isPrepaidSystem,
    isTimedManualSubscription,
    profileStatistics,
} from "./defaultSettings";

let list = [];
list = [
    ...list,
    {
        title: "صحفة مجموعات الاسئلة",
        text: "اضافة خانة تعديل قسم مجموعة الاسئلة",
        icon: "add",
        date: "17-nov-2022",
    },
    {
        title: "لوحة التحكم : ",
        text: "اضافة صفحة آخر التعديلات في المنصة",
        icon: "add",
        date: "16-nov-2022",
    },
    {
        title: "صفحات الإضافة و التعديل في لوحة التحكم :",
        text: " إضافة خاصية الحفظ و المتابعة بعد إضافة أي محتوى لتوفير وقت اضافة المحتوى المتكرر",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "صفحات إضافة و تعديل الكورسات و الأسئلة :",
        text: "القابلية لتعديل صورة الكورس و الأسئلة",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "صفحات إضافة و تعديل الكورسات و الأسئلة :",
        text: " عرض الصورة عند اختيار الكورس او السؤال للتعديل",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "لوحة التحكم : ",
        text: "عرض الصورة بعد رفعها في لوحة التحكم",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "لوحة التحكم :",
        text: "تغير شكل مؤشر التصفح الجانبي",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "صفحة تعديل مجموعات الكورس: ",
        text: "إضافة خانة تعديل الامتحانات اذا كانت مغلق عليها المحتوى",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "صفحة تعديل مجموعات الكورس: ",
        text: "إضافة خانة تعديل الامتحانات عدد مرات فتح الامتحان",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "جدول الفواتير : ",
        text: " خانة رقم هاتف المستخدم - جعلها قابلة للضغط و التوجيه لملف المستخدم",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "صفحة الكورس",
        text: "إضافة اسم المحتوى عند التنقل إليه داخل الكورس",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "مشاهدات الفيديو : ",
        text: "تحسين تسجيل مدة المشاهدة الكاملة",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الفيديوهات :",
        text: "تعطيل إعادة تحميل الصفحة عند التنقل بين فيديوهات الكورس",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الفيديوهات :",
        text: "اصلاح بعض المشاكل ب التكامل البرمجي مع انكربت",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح مشكلة ظهور NAN في احصائيات الامتحان داخل قسم محتوى الكورس في صفحة الكورس",
        icon: "fix",
        date: "14-nov-2022",
    },
];
if (profileStatistics) {
    list = [
        ...list,
        {
            title: "بروفايل المستخدم",
            text: "احصائيات الطالب خلال المنصة و الكورسات المشتركة في صفحته الشخصية",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}
if (isLoginTokens) {
    list = [
        ...list,
        {
            title: "لوحة التحكم :",
            text: "تحسين جدول سيشنات تسجيل الدخول",
            icon: "add",
            date: "14-nov-2022",
        },
        {
            title: "لوحة التحكم :",
            text: "تحسين جدول سيشنات تسجيل الخروج",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}
if (isBtns) {
    list = [
        ...list,
        {
            title: "صفحة إضافة اكثر من سؤال :",
            text: "اصلاح ازرار الكيبورد",
            icon: "improve",
            date: "14-nov-2022",
        },
    ];
}

list = [
    ...list,
    {
        title: "صفحة إضافة اكثر من سؤال :",
        text: "اصلاح ازرار الكيبورد",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الصفحة الرئيسية :",
        text: "تحسين كارت الكورس في الصفحة الرئيسية",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "مشاهدات الفيديو :",
        text: "تسجيل وقت آخر توقف للطالب في مشاهدة الفيديو",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "التصفح :",
        text: "تحسين شكل مؤشر التقدم في التصفح",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "التصفح :",
        text: "تحسين الاتجاه في الناف بار",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "صفحة إلغاء الاشتراك",
        text: "تحسين صفحة الغاء الاشتراك",
        icon: "improve",
        date: "14-nov-2022",
    },
];

if (isTimedManualSubscription) {
    list = [
        ...list,

        {
            title: "صفحة الدفع اليدوي :",
            text: "جعل الدفع اليدوي بالتاريخ",
            icon: "improve",
            date: "14-nov-2022",
        },
    ];
}
if (isCouponable) {
    list = [
        ...list,
        {
            title: "لوحة التحكم : ",
            text: "إضافة جدول الكوبونات",
            icon: "improve",
            date: "14-nov-2022",
        },
        {
            title: "لوحة التحكم : ",
            text: "إضافة صفحة إضافة و تعديل الكوبونات",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}
if (isPlatformSubscription) {
    list = [
        ...list,
        {
            title: "صفحة الدفع اليدوي :",
            text: "جعل الدفع اليدوي للمنصة بالكامل",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}
if (isPrepaidSystem) {
    list = [
        ...list,
        {
            title: "سيستم الدفع المسبق :",
            text: "اضافة سيستم الدفع المسبق",
            icon: "add",
            date: "14-nov-2022",
        },
        {
            title: "صفحة إضافة و تعديل الكورسات :",
            text: " إضافة خانة قابل للدفع المسبق؟",
            icon: "add",
            date: "14-nov-2022",
        },
        {
            title: "لوحة التحكم :",
            text: "إضافة صفحة تعديل الكورسات المسبقة الدفع للطلاب",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}
list = [
    ...list,
    {
        title: "جدول الاشتراكات : ",
        text: "تحسيل جدول الاشتراكات",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح مشكلة ظهور حالة الامتحان بعد الخروج من الامتحان",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح بعض المشاكل بالامتحانات",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح مشكلة ظهور رسالة لا توجد نتائج سابقة قبل تحميل النتائج",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "تغيير شكل  ظهور رسالة لا توجد نتائج سابقة و جعلها اكثر وضوحًا",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "منع الطالب من الخروج من الامتحان عند الضغط بالخطأ علي زر الرجوع",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح مشكلة ظهور NAN في رقم السؤال بالامتحان",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "إضافة زرار لظهور نتيجة الامتحان و انهائه من صفحة نتائج الامتحانات",
        icon: "add",
        date: "14-nov-2022",
    },
    {
        title: "الاختبارات : ",
        text: "اصلاح مشكلة بدأ الامتحان الغير حقيقي",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "اصلاح مشكلة :",
        text: "اصلاح ظهور مشكلة f is not function",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "تصفح الموقع : ",
        text: "الإنتقال إلى اعلى الصفحة عند التنقل من صفحة إلى أخرى",
        icon: "improve",
        date: "14-nov-2022",
    },
];

if (isCodes) {
    list = [
        ...list,
        {
            title: "اكواد المستخدمين و الاسم العشوائي : ",
            text: "اجبار تعديل الاسم للأكواد ",
            icon: "add",
            date: "14-nov-2022",
        },
        {
            title: "اكواد المستخدمين و الاسم العشوائي : ",
            text: "إضافة صفحة تعديل الاسم للمستخدم",
            icon: "add",
            date: "14-nov-2022",
        },
    ];
}

list = [
    ...list,

    {
        title: "صفحة انشاء الحساب : ",
        text: "تعديل بعض الكتابة بصفحة الاشتراك بالمنصة",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "الصور في نتائج الامتحان : ",
        text: "تعديل مشكلة الصور بنتائج الامتحانات",
        icon: "fix",
        date: "14-nov-2022",
    },
    {
        title: "أداء المنصة : ",
        text: "أزالة بعض الانيميشنز بالموقع لتحسين الأداء الكلي للموقع",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "صفحة محتوى الكورس : ",
        text: "تحسين شكل ظهور صورة الفيديو في قسم محتوى الكورس",
        icon: "improve",
        date: "14-nov-2022",
    },
    {
        title: "ظهور الاخطاء : ",
        text: "تحسين التعامل مع ارقام الأخطاء",
        icon: "improve",
        date: "14-nov-2022",
    },
];
if (isCodes) {
    list = [
        ...list,
        {
            title: "لوحة التحكم : ",
            text: "اختيار اذا كان الكورس كورس عادي او كورس اكواد",
            icon: "add",
            date: "13-nov-2022",
        },
        {
            title: "صفحة جدول الأكواد :",
            text: " ظهور خانة اختيار شكل ظهور الأكواد في كروت",
            icon: "add",
            date: "13-nov-2022",
        },
        {
            title: "صفحة إضافة الأكواد :",
            text: "إضافة خاصية الحفظ و المتابعة بعد إضافة الأكواد لتوفير وقت اضافة المحتوى المتكرر",
            icon: "add",
            date: "13-nov-2022",
        },
    ];
}
list = [
    ...list,

    {
        title: "صفحة إضافة و تعديل الامتحانات :",
        text: "إضافة خانة درجة النجاح في إضافة و تعديل الامتحان",
        icon: "add",
        date: "13-nov-2022",
    },
    {
        title: "لوحة التحكم :",
        text: "إضافة صفحة نقل و نسخ مجموعات الكورس من كورس لكورس آخر",
        icon: "add",
        date: "13-nov-2022",
    },
    {
        title: "جدول الإشتراكات :",
        text: " خانة رقم هاتف المستخدم - جعلها قابلة للضغط و التوجيه لملف المستخدم",
        icon: "improve",
        date: "13-nov-2022",
    },
];

export const updateReleases = list;
