import React from "react";

import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";

import time from "../../assets/burning-time.svg";
import calendar from "../../assets/calendar.svg";
import exam from "../../assets/contest.svg";

const AboutSection = () => {
    // const {scrolling}  = useContext
    // console.log("dsd");
    return (
        <>
            <section className="">
                {/* <Container>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
                        <div className="bg-primary-container smooth rounded-md shadow-xl px-10 py-10 flex-center-both flex-col">
                            <img src={exam} alt={"time"} className="w-full max-w-xs" />
                            <div className="font-h3 text-center bg-emerald-500 text-slate-100 font-w-bold px-5 py-2 rounded-md shadow-xl w-full max-w-xs">
                                امتحانات دورية و واجبات علي كل محاضرة
                            </div>
                        </div>
                        <div className="bg-primary-container smooth rounded-md shadow-xl px-5 py-10 flex-center-both flex-col">
                            <img src={time} alt={"time"} className="w-full max-w-xs" />
                            <div className="font-h3 text-center bg-yellow-300 text-slate-900 font-w-bold px-5 py-2 rounded-md shadow-xl w-full max-w-xs">
                                وفر وقت نزولك للدرس و ذاكر الجيولوجيا اونلاين!
                            </div>
                        </div>
                        <div className="bg-primary-container smooth rounded-md shadow-xl px-5 py-10 flex-center-both flex-col">
                            <img src={calendar} alt={"time"} className="w-full max-w-xs" />
                            <div className="font-h3 text-center bg-blue-500 text-slate-100 font-w-bold px-5 py-2 rounded-md shadow-xl w-full max-w-xs">
                                متابعة دائمة للطالب كأنك في السنتر !
                            </div>
                        </div>
                    </div>
                </Container> */}
            </section>
        </>
    );
};

export default AboutSection;
